
/**
 * @name useDeviceView
 * @description 判斷目前的畫面尺寸
 * @example
 * 
 *  <template>
 *    <!-- This component will mount only when mobile size screen -->
 *    <MobileMenu v-if="deviceView.mobile" />
 *  </template>
 * 
 *  <script lang="ts" setup>
 *     const deviceView = useDeviceView()
 *  </script>
 * 
 *   OR
 * 
 *  <script lang="ts">
 *    export default defineComponent({
 *      data(){
 *        return {
 *          deviceView: useDeviceView()
 *        }
 *      }
 *    },
 *  </script> 
 * 
 */


import { UAParser } from 'ua-parser-js'

const MB_WIDTH = 420
const PC_WIDTH = 1280

const breakpoints = [
  { name: 'xs', max: 615 },
  { name: 'sm', min: 616, max: 975 },
  { name: 'md', min: 976, max: 1279 },
  { name: 'lg', min: 1280, max: 1919 },
  { name: 'xl', min: 1920 },
  { name: 'mobile', max: 975 }, // 二分法：手機
  { name: 'desktop', min: 976 }, // 二分法：電腦
  { name: 'tablet', min: 976, max: 1279 }, // 三分法平板
]
interface DeviceView {
  width: number
  [viewport: string]: boolean | number,
}

export const useDeviceView = () => {
  const requestUA = useRequestHeaders()["user-agent"];
  const deviceType = UAParser(requestUA).device.type;
  const isMobile = UAParser.DEVICE.MOBILE === deviceType || UAParser.DEVICE.TABLET === deviceType;
  const defaultWidth = isMobile ? MB_WIDTH : PC_WIDTH;

  const deviceView = useState<DeviceView>("device-view", () =>
    breakpoints.reduce<DeviceView>(
      (acc, { name, max, min }) => {
        if (min && max) {
          acc[name] = defaultWidth >= min && defaultWidth < max;
        } else if (max) {
          acc[name] = defaultWidth < max;
        } else if (min) {
          acc[name] = defaultWidth >= min;
        }
        return acc;
      },
      {
        width: defaultWidth,
      }
    )
  );
  
  onMounted(() => {
    function defineRule(name: string, expression: string) {
      const matcher = window.matchMedia(expression)
      deviceView.value[name] = matcher.matches
      try {
        // Chrome & Firefox
        matcher.addEventListener('change', ({ matches }) => {
          deviceView.value.width = window.innerWidth
          deviceView.value[name] = matches
        })
      } catch (error) {
        // Safari 14
        matcher.addListener(({ matches }) => {
          deviceView.value[name] = matches
        })
      }
    }

    breakpoints.forEach(({ name, min, max }) => {
      const eMin = min ? `(min-width: ${min}px)` : ''
      const eMax = max ? `(max-width: ${max}px)` : ''
      defineRule(name, ['all', eMin, eMax].filter(exist => exist).join(' and '))
      if (eMin) defineRule(`>=${min}`, `all and ${eMin}`)
      if (eMin) defineRule(`>=${name}`, `all and ${eMin}`)
      if (eMax) defineRule(`<${name}`, `all and ${eMax}`)
    })
  })

  return unref(deviceView)
};
